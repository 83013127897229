.customCalendarSelectedDay {
    border-radius: 4px!important;
    background-color:var(--chakra-colors-darkGrey-500)!important;
    min-width:calc(22px)!important;
    max-width:calc(22px)!important;
    min-height:calc(22px)!important;
    max-height: calc(22px)!important;
}
.customCalendarTodayClassName {
    color: var(--chakra-colors-darkGrey-500)!important;
    font-weight:400!important;
}
.customCalendarTodayClassName:after {
    background: var(--chakra-colors-blue-500)!important;
    height:2px!important
}
.Calendar__day {
  font-size: 11px!important;
  min-width:calc(22px)!important;
  max-width:calc(22px)!important;
  min-height:calc(22px)!important;
  max-height: calc(22px)!important;
}
.Calendar__weekDay {
  min-width:calc(22px)!important;
    max-width:calc(22px)!important;
    min-height:calc(22px)!important;
    max-height: calc(22px)!important;
}
.Calendar__day:not(.-selected):hover {
  border-radius: 4px!important;

}
.Calendar__weekRow {
  width:calc(100%)!important;
  display:flex!important;
  gap:calc((100% - (22px*7))/6);
  padding: 0px 10px !important;
  margin-top: 20px;
}
.Calendar__weekDays {
  width:calc(100%)!important;
  margin-top: 20px;
  display:flex!important;
  gap:calc((100% - (22px*7))/6);
  padding: 0px 10px!important;
  justify-content: none;
}
.customCalendarClassName {
    min-width:100%!important;
    max-width:100%!important;
    min-height:100%!important;
    max-height:100%!important;
    padding:40px!important;
    margin:0!important;
}
.Calendar__section.-shown {
  padding:0!important
}
.Calendar__header {
  padding:0!important

}
.daysWithPublications:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    color: var(--chakra-colors-blue-500)!important;
}
