.carousel-custom-container {
    justify-content: start!important;
}
.carousel-custom-slider{
    height:100%;

}
.react-multi-carousel-dot-list {
  padding-bottom: 20px
}


